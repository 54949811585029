/**
 * 训练相关状态
 */
import comm from "../../utils/common";
import Vue from 'vue';
import Vuex from 'vuex';
import app from '../../main'

Vue.use(Vuex);

const state = () => ({
    //组件回传的结果
    myScriptResult:null,
    //当前空格索引，默认0
    currentEmptyIndex:0,
    //答案列表
    answerList:[],
    //是否显示myScript组件
    isShowMyScript:false,
    //是否清空myScript组件
    isClearMyScript:false,
    //是否清空键盘
    isClearKeyboard:false,
    //是否激活myScript
    isFocusMyScript:false,
    //是否下一个题
    isNextTopic:false,

})

const getters = {
    //获取myScript组件回传结果
    getMyScriptResult:state=>state.myScriptResult,
    //获取当前空格索引
    getCurrentEmptyIndex:state=>state.currentEmptyIndex,
    //获取当前答案列表
    getAnswerList:state=>state.answerList,
    //获取组件显示状态
    getMyScriptStatus:state=>{
        return state.isShowMyScript
    },
    //获取清空组件状态
    getMyScriptClearStatus:state=>state.isClearMyScript,
    //获取清空键盘状态
    getKeyboardClearStatus:state=>state.isClearKeyboard,
    //获取下一题可否状态
    getNextTopicStatus:state=>state.isNextTopic,
}

const mutations = {
    //设置组件结果
    setMyScriptResult(state,data){
        app.$set(state,'myScriptResult' , data);
    },
    //设置组件当前空格索引
    setCurrentEmptyIndex(state,data){
        state.currentEmptyIndex = data;
    },
    //下一空
    nextEmpty(state){
        let maxLen = state.answerList.length;
        if(state.currentEmptyIndex < maxLen - 1){
            state.currentEmptyIndex ++;
        }else{
            state.currentEmptyIndex = 0;
        }
        state.isClearMyScript = true;
    },
    //设置指定答案，{index,value}
    setAnswerList(state,data){
        console.log('data in setAnswerList', data);
        for(let index in state.answerList){
            if(Number(index) === data.index){
                // state.answerList[index] = data.value;
                Vue.set(state.answerList,index,data.value);

            }
        }
        console.log('state.answerList_____', state.answerList);
        // state.answerList = data;
    },
    //初始化答案
    initAnswerList(state,data){
        console.log('init answerList data', data);
        state.answerList = data;
    },
    //设置组件显示与否
    setMyScriptStatus(state,status){
        state.isShowMyScript = status;
    },
    //设置myScript清空状态
    setMyScriptClearStatus(state,status){
        console.log('setMyScriptClearStatus status', status);
        state.isClearMyScript = status;
    },
    //设置键盘清空状态
    seKeyboardClearStatus(state,status){
        state.isClearKeyboard = status;
    },
    //设置下一题可否状态
    setIsNextTopicStatus(state,status){
        state.isNextTopic = status;
    }
}

const actions = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}