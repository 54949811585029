import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// const originalPush = Router.prototype.push
//
// Router.prototype.push = function push (location) {
//
//   return originalPush.call(this, location).catch(err => err)
//
// }

// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'Index',
        component: resolve => require(['../views/Index.vue'], resolve),
        children: [
            {
                path: '/Task',
                name: 'Task',
                component: resolve => require(['../views/main/Task.vue'], resolve),
            },
            {
                path: '/Test',
                name: 'Test',
                component: resolve => require(['../views/main/Test.vue'], resolve),
            },
            {
                path: '/Exam',
                name: 'Exam',
                component: resolve => require(['../views/main/Exam.vue'], resolve),
            },
            {
                path: '/Me',
                name: 'Me',
                component: resolve => require(['../views/main/Me.vue'], resolve),
            },{
                path: '/Wrong',
                name: 'Wrong',
                component: resolve => require(['../views/main/Wrong.vue'], resolve),
            },
        ]
    },
    {
        path: '/TestPage/:testId/:isRestart',
        name: 'TestPage',
        component: resolve => require(['../views/task/TestPage.vue'], resolve),
    },
    {
        path: '/TestPage1/:testId/:isRestart',
        name: 'TestPage',
        component: resolve => require(['../views/test/TestPage.vue'], resolve),
    },
    {
        path: '/Login',
        name: 'Login',
        component: resolve => require(['../views/Login.vue'], resolve),
    },
    //扫码登录
    {
        path: '/CodeLogin',
        name: 'CodeLogin',
        component: resolve => require(['../views/CodeLogin.vue'], resolve),
    },
    {
        path: '/Demo',
        name: 'Demo',
        component: resolve => require(['../views/Demo.vue'], resolve),
    },
    {
        path: '/Register',
        name: 'Register',
        component: resolve => require(['../views/Register.vue'], resolve),
    },
    {
        path: '/Retrieve',
        name: 'Retrieve',
        component: resolve => require(['../views/Retrieve.vue'], resolve),
    },
    {
        path: '/Agreement',
        name: 'Agreement',
        component: resolve => require(['../views/Agreement.vue'], resolve),
    },
    {
        path: '/WrongBook',
        name: 'WrongBook',
        component: resolve => require(['../views/WrongBook.vue'], resolve),
    },
    {
        path: '/download/question',
        name: 'DownloadQuestion',
        component: resolve => require(['../views/test/DownloadQuestion.vue'], resolve),
    },
    {
        path: "/task/feedback",
        name: "taskFeedback",
        component: () => import("../views/task/Feedback.vue"),
        meta: {
            keepAlive: false // 不需要缓存
        }
    },
    {
        path: "/task/fa",
        name: "taskFa",
        component: () => import("../views/task/Fa.vue")
    },
    {
        path: "/task/title_list",
        name: "titleList",
        component: () => import("../views/task/TitleList.vue"),
        meta: {
            keepAlive: false // 需要被缓存
        }
    },
    {//学生考试分析
        path: "/analysis/studentExamination",
        name: "studentExamination",
        component: () => import("../views/analysis/studentExamination.vue"),
        meta: {
            keepAlive: false//需要缓存
        }
    },
    {
        path: "/analysis/studentTestPaper",
        name: "studentTestPaper",
        component: () => import("../views/analysis/studentTestPaper.vue"),
        meta: {
            keepAlive: false//需要缓存
        }
    },
    {
        path: "/Analysis",
        name: "Analysis",
        component: () => import("../views/Analysis.vue"),
    },
]

const router = new Router({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes
})

export default router
