/**
 * 通用模块框架模板
 */
import comm from "../../utils/common";
import app from '../../main'
import Vue from 'vue';
import Vuex from 'vuex';
import UserApi from "../../api/UserApi";
import local from "../../utils/localStorage";

Vue.use(Vuex);

const state = () => ({
    //用户信息
    userInfo:{},
})

const getters = {
    //获取用户信息
    getUserInfo:state=>state.userInfo,
}

const mutations = {
    //设置用户信息
    setUserInfo(state,userInfo){
        state.userInfo = userInfo;
        local.myLocal('__USER_INFO__', userInfo);
    }
}

const actions = {
    catchUserInfo({commit},params){
        return UserApi.userInfo().then(res => {
            console.log('getUserInfo res', res);
            if (res.success) {
                commit('setUserInfo', res.data);
                app.$L.myLocal('__USER_INFO__',res.data);

            }
            // else if (res.code === 401) {
            //     if (app.$route.path !== '/Login') {
            //         app.$toast.loading({
            //             message: '用户失效\n请重新登录',
            //             forbidClick: true,
            //             duration: 1000
            //         })
            //         setTimeout(() => {
            //
            //             if(app.$route.path !== '/Login'){
            //                 app.$router.replace({path: '/Login'})
            //             }
            //
            //
            //
            //         }, 1000)
            //     }
            //
            // }


            return res;
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}