import { post,get } from "./http";

const UserApi = {
    // 用户登录
    login: params => post(`/index.php?r=mobileapp/profile/login`, params),
    // 用户注销
    closeLogin: params => post(`/index.php?r=mobileapp/profile/closeLogin`, params),
    // 获取用户信息
    userInfo: params => post(`/index.php?r=mobileapp/profile/userInfo`, params),
    // 修改密码
    updatePwd: params => post(`/index.php?r=mobileapp/profile/updatePwd`, params),
    // 获取手机验证码
    sendBindPhoneVerifyCode: params => post(`/index.php?r=mobileapp/profile/sendBindPhoneVerifyCode`, params),
    // 绑定微信
    bindWeChat: params => post(`/index.php?r=mobileapp/profile/bindWeChat`, params),
    //绑定手机
    bindPhoneNumber:params=>post(`/index.php?r=mobileapp/profile/bindPhoneNumber`,params),
    //错题本
    wrongQuestions:params=>post(`/index.php?r=mobileapp/examPublicList/WrongQuestions`,params),
    //扫码加入考试接口
    addExaminationScan:params=>post(`/index.php?r=mobileapp/examPublicList/AddExaminationScan`,params)
}
export default UserApi