/**
 * 通用模块框架模板
 */
// import comm from "../../utils/common";
import CommonApi from '../../api/common'
// import UserApi from '../../api/user'
import Vue from 'vue';
import Vuex from 'vuex';
import local from "../../utils/localStorage";
import app from '../../main'

Vue.use(Vuex);

const state = () => ({
    //年级列表
    gradeList: [],
    //科目列表
    subjectList: [],
    //用户信息
    // userInfo: {},
    //当前选择年级
    currentGradeId: 0,
    //学校列表
    schoolList: [],
    //公众号信息
    wxList : [],

    schoolId:63317,//默认学校id
    //logo和联系地址
    logoInfo:[
        {
            schoolId:63317,
            title:'源思维',
            logoSrc:'/transform/defaultLogo.png',
            loginMode: 'school',
            loginModeShow  : {
                'school' : true,
                'default' : true
            },
            contact:{
                phone:'18177143156',
                person:'微信同号：小维老师',
                address:'地址：金湖路63号金源CBD现代城B座508室'

            }
        },
        {
            schoolId:63324,
            title:'郑当代',
            logoSrc:'/transform/logoZDD.png',
            loginMode: 'default',
            loginModeShow  : {
                'school' : false,
                'default' : true
            },
            contact:{
                phone:'66331968',
                person:'',
                address:'方圆校区地址：陇海路紫荆山路交叉口方圆创世A座5楼'

            }
        },

    ],
})

const getters = {
    //获取logo数据
    getLogoImg:state=>{
        let schoolId = app.$L.myLocal('__SCHOOL_ID__') || state.schoolId;

        let target = state.logoInfo.filter(item => item.schoolId == schoolId);
        // console.log('target', target);
        // debugger
        if (target[0]) {
            return target[0].logoSrc;
        }
        return null;
    },
    //获取年级列表
    getGradeList: (state) => state.gradeList,
    //获取科目列表
    getSubjectList: (state) => {
        return state.subjectList;
        // let whiteList = ['数学','物理','化学'];
        // return state.subjectList.filter(item=>whiteList.indexOf(item.name) !== -1);
    },

    // //获取用户信息
    // getUserInfo: (state) => state.userInfo,
    //获取当前年级id
    getCurrentGradeId: (state) => state.currentGradeId,
    //获取学校列表
    getSchoolList: state => state.schoolList,

    //获取微信数据列表
    getWxInfoList:state=>state.wxList,
    //根据学校id获取微信公众号信息
    getWxInfoBySchoolId:(state)=>(schoolId)=>{
        // let data = res.data;
        let appid = '';
        for (let key in state.wxList) {
            // console.log(key);
            // debugger
            if (Number(key) === schoolId) {
                appid = state.wxList[key].appid;
                break;

            }
        }
        // console.log('appid', appid);
        return appid;
    },

    //获取logo信息
    getLogoInfo:state=>state.logoInfo,

    //获取学校id,默认0 源思维
    getSchoolId:state=>state.schoolId || 63317,
}

const mutations = {
    // //设置年级列表
    setGradeList(state, data) {
        state.gradeList = data;
    },
    // //设置科目列表
    setSubjectList(state, data) {
        state.subjectList = data;
    },

    //设置用户信息
    // setUserInfo(state, data) {
    //     state.userInfo = data;
    //     local.myLocal('__USER_INFO__', data);
    //     // console.log('data token', data);
    //     // local.myLocal('__TOKEN__',data.token);
    // },
    //设置当前选择的年级id
    setCurrentGradeId(state, data) {
        state.currentGradeId = data
    },
    //设置学校列表
    setSchoolList(state, data) {
        state.schoolList = data;
    },
    //设置微信公众号列表数据
    setWxList(state,data){
        state.wxList = data;
    },

    //设置学校id
    setSchoolId(state,id){
      state.schoolId = id;
    }
}

const actions = {
    //远端拉去用户信息
    // getUserInformation({commit}, params) {
        // return UserApi.getUserInfo().then(res => {
        //     console.log('getUserInfo res', res);
        //     if (res.success) {
        //         commit('setUserInfo', res.data);
        //
        //     } else if (res.code === 401) {
        //         if (app.$route.path !== '/login') {
        //             app.$toast.loading({
        //                 message: '用户失效\n请重新登录',
        //                 forbidClick: true,
        //                 duration: 1000
        //             })
        //             setTimeout(() => {
        //
        //                 app.$router.replace({path: '/login'})
        //
        //
        //             }, 1000)
        //         }
        //
        //     }
        //
        //
        //     return res;
        // })
    // },
    //获取年级信息数据
    catchGradeList({commit}, params) {
        return CommonApi.getGradeList(params).then(res => {
            if (res.success) {
                commit('setGradeList', res.data)
            }
            return res;
        })
    },
    //获取年级信息数据
    catchSubjectList({commit}, params) {
        return CommonApi.getSubjectList(params).then(res => {
            if (res.success) {
                commit('setSubjectList', res.data);
            }
            return res;
        })
    },
    //获取学校列表信息
    // getSchoolInfo({commit}, params) {
    //     return CommonApi.getSchools(params).then(res => {
    //         if (res.success) {
    //             commit('setSchoolList', res.data);
    //         }
    //         return res;
    //     })
    // },
    //
    // //获取微信公众号列表数据
    // getWxListInfo({commit},params){
    //     return CommonApi.getWxList(params).then(res=>{
    //         if(res.code === 200){
    //             commit('setWxList',res.data);
    //         }
    //     })
    // }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}