
// import comm from './common'

const local = {
    //获取、设置本地localstorage
    myLocal(keyName, data = {}) {

        if (JSON.stringify(data) !== '{}') {
            if(typeof data ==='object'){
                // console.log('localStorage type : object');
                localStorage.setItem(keyName, JSON.stringify(data));
            }else if(typeof data ==='string' || typeof data === 'number'){
                // console.log('localStorage type : string');
                localStorage.setItem(keyName,data);
            }
        }
        let res;
        try{
            res = JSON.parse(localStorage.getItem(keyName))
        }catch (e) {
            res = localStorage.getItem(keyName)
;        }
        return res;
    },

	removeLocal(...keyNames){
		console.log(keyNames);
		if(keyNames && keyNames.length){
			for (let i = 0 ; i < keyNames.length; i++){
				console.log(keyNames[i]);
				if(this.myLocal(keyNames[i])) localStorage.removeItem(keyNames[i]);
			}
		}
	}

};

export default local;
