
const zxs = {
    /**
     * 根据科目id获取科目名称
     * @param subjectId 科目id
     * @param subjectList 科目列表
     */
    getSubjectTitleById(subjectId,subjectList){
        let target;
        try {
            target = subjectList.filter(item=>item.subjectId == subjectId)[0];
            return target.name;
        }catch (e) {
            target = [];
        }
        return '';

    },

    /**
     * 根据年级id获取年级名称
     * @param gradeId
     * @param gradeList
     */
    getGradeTitleById(gradeId,gradeList){
        // console.log('gradeId,gradeList', gradeId, gradeList);
        let target ;
        try {
            target = gradeList.filter(item=>item.id == gradeId)[0];
            return  target.name;
        }catch (e) {

        }
        return '';
    },

    /**
     * 获取科目颜色
     * @param subjectName 科目名称
     * @returns {string} 返回颜色字符串
     */
    getSubjectColor(subjectName) {
        let color = '';
        switch (subjectName) {
            case '语文':
                color = '#00A0E8'
                break;
            case '数学':
                color = '#FE4B4A'
                break;
            case '英语':
                color = '#FECB00'
                break;
            case '政治':
                color = '#00D06C'
                break;
            case '物理':
                color = '#F29B75'
                break;
            case '化学':
                color = '#EB68A2'
                break;
            case '生物':
                color = '#8FC21F'
                break;
            case '地理':
                color = '#12B5B1'
                break;
            case '历史':
                color = '#FEAD00'
                break;

        }
        return color;
    },

    //初始化MathJax
    initMathJax(DOM) {
        setTimeout(() => {
            // console.log(11111);
            if (window.MathJax) {
                // console.log(22222);
                window.MathJax.Hub.Queue([
                    'Typeset',
                    window.MathJax.Hub,
                    DOM
                ])
            }
            // console.log('window.MathJax', window.MathJax);
        }, 300)
    },
}
export default zxs