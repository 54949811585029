/*
 * @Author: 艾斯
 * @Date: 2020-02-18 23:13:32
 * @LastEditTime : 2020-02-19 09:39:47
 * @LastEditors  : Please set LastEditors
 * @Description:
 * @FilePath: /vue/yunxue/src/main.js
 */
import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import Vant from 'vant';
import store from './store'
import 'vant/lib/index.css';
import VCharts from 'v-charts'
import comm from "./utils/common";
import local from './utils/localStorage'
// import './assets/js/MathJax.js'
import './assets/js/myScriptPep'//myScript兼容工具
import Vuex from 'vuex';
import zxs from "./utils/zxs";

import echarts from "echarts";
Vue.prototype.$echarts = echarts;

Vue.use(Vuex);

Vue.use(Vant);
Vue.use(VCharts)

Vue.prototype.$A = comm;  //通用工具方法
Vue.prototype.$L = local; // 本地相关操作
Vue.prototype.$Z = zxs; // 自修室相关公共方法

Vue.prototype.goBack = function (number) {
  if (window.history.length > 1) {
    window.history.go(typeof number === 'number' ? number : -1)
  } else {
    window.location.href = `${window.location.protocol}//${window.location.host}#/task`;
  }
};


Vue.config.productionTip = false;
if(process.env.NODE_ENV === "development"){
  const VConsole = require('./assets/js/vConsole');
}




const that = new Vue({
  router,
  store,
  components: {App},
  template: '<App/>',
  // render: h => h(App),
}).$mount('#app');

export default that;
