<template>
    <div id="app">
        <transition name="van-slide-right">
            <keep-alive>
                <router-view class="view-child"/>
            </keep-alive>
        </transition>
    </div>
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    export default {
        mounted() {
        },
        watch: {
            $route: {
                handler(to, from) {
                    // console.log('第一次进来 ： ',this.$route.query.schoolId)


                    //设置默认学校id
                    /*let schoolId;
                    if (this.$route.query.schoolId) {
                        schoolId = this.$route.query.schoolId;
                        this.setSchoolId(schoolId);
                    } else {
                        schoolId = this.$L.myLocal('__SCHOOL_ID__') ||  this.schoolId;
                    }
                    this.$L.myLocal('__SCHOOL_ID__', schoolId);

                    let info = this.logoInfo.filter(item => item.schoolId == schoolId);
                    let target = info.length > 0 ? info[0] : [];
                    document.title = (target && target.title) ? target && target.title : '源思维';
                    console.log('进来了么1： ',this.schoolId)

                    //检查公共科目列表和年级列表

                    if (this.gradeList.length === 0) {
                        this.catchGradeList()
                    }
                    if (this.subjectList.length === 0) {
                        this.catchSubjectList();
                    }*/

                },
                immediate: true,

            }
        },
        created() {
            this.getSchoolId()
        },
        computed: {
            ...mapGetters({
                //学校id
                schoolId: 'common/getSchoolId',
                //logo信息
                logoInfo: 'common/getLogoInfo',
                //年级列表
                gradeList: 'common/getGradeList',
                //科目列表
                subjectList: 'common/getSubjectList'
            })
        },
        methods: {
            ...mapMutations({
                //设置学校id
                setSchoolId: 'common/setSchoolId',
                //设置用户信息
                setUserInfo: 'user/setUserInfo'

            }),
            ...mapActions({
                //抓取年级列表
                catchGradeList: 'common/catchGradeList',
                //抓取科目列表
                catchSubjectList: 'common/catchSubjectList',
                //获取用户信息：
                catchUserInfo:'user/catchUserInfo'
            }),

            getSchoolId(){
                let schoolId = this.$A.getHrefQueryByName('schoolId')
                console.log('school : ', schoolId)
                if (schoolId){
                    this.setSchoolId(schoolId);
                    this.$L.myLocal('__SCHOOL_ID__', schoolId);
                }

                let info = this.logoInfo.filter(item => item.schoolId == schoolId);
                let target = info.length > 0 ? info[0] : [];
                document.title = (target && target.title) ? target && target.title : '源思维';

                //检查公共科目列表和年级列表

                if (this.gradeList.length === 0) {
                    this.catchGradeList()
                }
                if (this.subjectList.length === 0) {
                    this.catchSubjectList();
                }
                // console.log('第一次进来 ： ',location.href,location.search,this.$route)
            }

        }
    }
</script>

<style lang="scss">

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: $defaultColor;

        font-size: 14px;

        //判断ipad
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            font-size: 24px;
        }

        .view-child {
            @extend .abs_full_screen;
            background: #fff;
        }
    }

    // 全局变量(默认是散用户，及个人微信登录的样式主题)
    :root {
        --tabbackcolor: #fff; // tabbar背景颜色（白色）
        --background: #f1f1f1; // 背景颜色（灰色）
        --fontcolor: #333; // 字体颜色（黑色）
        --fontcolor1: #777; // 字体颜色（深灰色）
        --fontcolor2: #999; // 字体颜色（浅灰
        --fontcolor3: #fff; // 字体颜色（白色
        --themecolor: #02c1ae; // 默认主题色（大部分按钮以及背景）
        --vipbgcolor: linear-gradient(to right, #fff06c, #fecd96); // vip的背景色
        --vipbgactive: linear-gradient(to right, #fecd96, #fff06c); // vip点击背景色
        --vipfontcolor: #b39595; // vip字体颜色
        --freebgcolor: linear-gradient(to right, #66c8ee, #8fe1da); // 免费背景颜色
        --freefontcolor: #e4f2f2; // 免费字体颜色
        --undobg: #02c1ae; // 开始测试背景色
        --undobgactive: #04a280; // 开始测试点击效果
        --doingbg: #34dbf3; // 继续测试背景色
        --doingbgactive: #2cb8f3; // 继续测试点击效果
        --donebg: #fecd96; // 分析报告背景色
        --donebgactive: #fead8f; // 分析报告点击效果
        --selfbg: linear-gradient(to right, #00d5e8, #02c1ae); // 个人中心头部背景色
        --selfbgactive: linear-gradient(to left, #00d5e8, #02c1ae); // 个人中心头部背景色
        --tgcolor: #cda66b; // 推广主题色
        --tgbgcolor: linear-gradient(to right, #cba96a, #dbbf71); // 推广的卡片背景
        --processbgcolor: #ccc; // 进度条无填充背景
        --processbgac: linear-gradient(
                        to right,
                        #8be7ff,
                        #1cc5f0
        ); // 进度条背景(有进度的时候)
        --numbgcolor: #fff; // 键盘数字背景
        --funbgcolor: #2cb8f3; // 键盘功能背景色
    }

    .van-dialog.myDialog {
        .van-dialog__confirm, .van-dialog__confirm:active {
            color: $themeColor;
        }
    }


    @font-face {
        font-family: "my-icon";
        src: url("../public/fonts/iconfont.ttf?t=1583291810839") format("truetype");
    }
    .my-icon {
        font-family: "my-icon";
        font-style: normal;
    }

    .my-icon-dili-:before {
        content: "\e602";
    }

    .my-icon-huaxue-:before {
        content: "\e604";
    }

    .my-icon-lishi-:before {
        content: "\e605";
    }

    .my-icon-shengwu-:before {
        content: "\e606";
    }

    .my-icon-shuxue-:before {
        content: "\e607";
    }

    .my-icon-wuli-:before {
        content: "\e608";
    }

    .my-icon-yingyu-:before {
        content: "\e609";
    }

    .my-icon-yuwen-:before {
        content: "\e60a";
    }

    .my-icon-zhengzhi-:before {
        content: "\e60c";
    }

    .my-icon-weixin1:before {
        content: "\e603";
    }

    .my-icon-dui:before {
        content: "\e60b";
    }

    .my-icon-bandui:before {
        content: "\e600";
    }

    .my-icon-cuo:before {
        content: "\e61d";
    }



    //判断ipad
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {

        /*  对话弹窗*/
        .van-dialog.myDialog {
            width: 640px;
            font-size: 32px;
            border-radius: 32px;

            .van-dialog__header {
                padding-top: 52px;
                line-height: 48px;
            }

            .van-dialog__message--has-title {
                padding-top: 16px;
            }

            .van-dialog__message {
                font-size: 28px;
                padding: 52px 48px;
                line-height: 40px;
            }

            .van-dialog__cancel, .van-dialog__confirm {
                height: 96px;
            }

            .van-button {
                font-size: 32px;
            }
        }

        #app {
            /*
            TODO  2、tab页面大屏样式
            */
            .van-tabs--line .van-tabs__wrap {
                height: 88px;
            }
        }

        /*toast样式*/
        .van-toast {
            min-width: 200px;
            width: auto !important;

            &.van-toast--text {
                min-width: 192px;
                padding: 16px 24px;
            }

            .van-toast__text {
                font-size: 28px;
                line-height: 40px;
            }

            .van-loading__spinner {
                width: 60px;
                height: 60px;
            }

            .van-toast__icon {
                font-size: 72px;
            }
        }

        /*提示样式*/
        .van-notify {
            font-size: 28px !important;
            line-height: 40px !important;
        }

    /*    下拉刷新字体*/
        .van-loading__spinner.van-loading__spinner--circular{
            width: 32px !important;
            height: 32px !important;
        }
        /*下拉加载问题本*/
        .van-loading__text{
            font-size: 28px !important;
        }
        /*下拉提示文本*/
        .van-pull-refresh__text{
            font-size: 28px;
        }







    }


</style>
