// import {Request} from "../utils/request";
import {post,get} from './http'

export default {
    //获取年级列表
    getGradeList:(params)=>post('/index.php?r=mobileapp/utils/getGrades',params),
    //获取科目列表
    getSubjectList:(params)=>post('/index.php?r=mobileapp/utils/getSubjects',params),

    //考试题目 - 获取考试题目
    getExamQuestion:(params)=>post('/index.php?r=mobileapp/OnLineTestExam/GetOnlineTestExamQuestions',params),

    //考试题目 - 提交答案
    postAnswer: (params) => post('/index.php?r=mobileapp/OnLineTestExam/PostQuestionAnswer', params),

    //用户反馈
    feedbackSubmit: (params) => post('/index.php?r=mobileapp/mobQuestion/reportQuestion', params),
    //上传图片
    uploadImage: (params) => post('/index.php?r=mobileapp/profile/uploadImage', params),


    //下载试卷题目
    downloadTestPaperQuestions : (params) => post('/index.php?r=mobileapp/download/testPaperQuestion', params)
}
