import QS from "qs";
import Vue from "vue";
import Axios from "axios";
import base from '../common/base';
import app from '../main';
import {Toast, Notify} from "vant";

Vue.use(Toast).use(Notify);

// 环境的切换
const baseDev = `https://u2.yuansiwei.com`,
// const baseDev = `http://zm.vipgz4.idcfengye.com`,
// const baseDev = `http://zijing.imwork.net/`,
// const baseDev = `http://192.168.1.42:8081/`,
// const baseDev = `http://192.168.1.42:8089/`,
    basePro = `${window.location.protocol}//u2.yuansiwei.com`;
    // basePro = `${window.location.protocol}//zijing.imwork.net/`;
Axios.defaults.baseURL =
    process.env.NODE_ENV === "development" ? baseDev : basePro;
export default Axios.defaults.baseURL;

// 请求超时时间
Axios.defaults.timeout = 10000;

// header携带cookie
// Axios.defaults.withCredentials = true;
// Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
Axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

// 请求拦截器
Axios.interceptors.request.use(
    config => {
        let token = app.$L.myLocal('__SESSION__');

        // console.log('token', token, 'config.url', config.url);
        //请求接口地址中不包含login表示不是登录接口
        if (token && (config.url.indexOf('/login') === -1)) {
            config.headers['token'] = app.$L.myLocal('__SESSION__');
            //get请求拼接公共参数
            if (config.method === 'get') {
                let str = `appName=${base.appName}&session=${app.$L.myLocal('__SESSION__')}`;
                if (config.baseURL.indexOf('?') !== -1) {
                    //已存在问号参数
                    config.url += `&${str}`;
                } else {
                    config.url += `?${str}`;
                }
            }

            //post请求添加公共参数
            let commParams = config.data || {};
            // commParams.version =  base.version;
            commParams.appName = base.appName;
            // commParams.platform = base.platform;
            commParams.session = app.$L.myLocal('__SESSION__');

            config.data = commParams;
            config.data = QS.stringify(config.data);
        } else {
            config.data = QS.stringify(config.data);
        }
        return config;
    },
    error => {
        return Promise.error(error);
    }
);

// 响应拦截器
Axios.interceptors.response.use(
    response => {
        if (!response.data.success) {
            Notify(response.data.msg)
        }
        return response
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                case 401:
                    var toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: '返回登录 3 秒'
                    });

                    var second = 3;
                    var timer = setInterval(() => {
                        second--;
                        if (second) {
                            toast.message = `返回登录 ${second} 秒`;
                        } else {
                            clearInterval(timer);
                            // 手动清除 Toast
                            Toast.clear();
                            // window.location.href = "/login"
                            if(app.$route.path !== '/Login'){
                                app.$router.replace({path:'/Login',query:{schoolId:app.$route.query.schoolId}})
                            }


                        }
                    }, 1000);

                    break;
                // 403 token过期
                case 403:
                    break;
                // 404请求不存在
                case 404:
                    break;
                // 其他错误，直接抛出错误提示
                case 500:
                    Notify('服务器出错')
                    break;

            }
            return Promise.reject(error);
        }
    }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        Axios
            .get(url, {
                params: params
            })
            .then(res => {

                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            });
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    // if (process.env.NODE_ENV === "development") {
    //   params.appName = 'webFrontEnd'
    // }

    return new Promise((resolve, reject) => {
        Axios
            // .post(url, QS.stringify(params))
            .post(url, params)
            .then(res => {
                if (res && res.data) {
                    resolve(res.data);
                } else {
                    reject('数据异常');
                }

            })
            .catch(err => {
                reject(err, '__', err.data);
            });
    });
}
