const comm = {

	/**
	 * 请求类
	 */




	/**
	 * 工具函数
	 */

	/**
	 * 是否微信小程序
	 * @returns {boolean|string}
	 */
	isWxapp() {
		let ua = typeof window !== 'undefined' && window.__wxjs_environment;
		return ua === 'miniprogram';
	},

	/**
	 * 是否IOS
	 * @returns {boolean|string}
	 */
	isIos() {
		let ua = typeof window !== 'undefined' && window.navigator.userAgent.toLowerCase();
		return ua && /iphone|ipad|ipod|ios/.test(ua);
	},

	/**
	 * 是否安卓
	 * @returns {boolean|string}
	 */
	isAndroid() {
		let ua = typeof window !== 'undefined' && window.navigator.userAgent.toLowerCase();
		return ua && ua.indexOf('android') > 0;
	},

	/**
	 * 是否微信
	 * @returns {boolean}
	 */
	isWeixin() {
		let ua = typeof window !== 'undefined' && window.navigator.userAgent.toLowerCase();
		return (ua.match(/MicroMessenger/i) + '' === 'micromessenger');
	},

	/**
	 * 检测手机号码格式
	 * @param str
	 * @returns {boolean}
	 */
	isMobile(str) {
		return /^1(3|4|5|6|7|8|9)\d{9}$/.test(str);
	},
	/**
	 * 补零
	 * @param str
	 * @param length
	 * @param after
	 * @returns {*}
	 */
	zeroFill(str, length, after) {
		str += "";
		if (str.length >= length) {
			return str;
		}
		let _str = '', _ret = '';
		for (let i = 0; i < length; i++) {
			_str += '0';
		}
		if (after || typeof after === 'undefined') {
			_ret = (_str + "" + str).substr(length * -1);
		} else {
			_ret = (str + "" + _str).substr(0, length);
		}
		return _ret;
	},

	/**
	 * 时间戳转时间格式
	 * @param format
	 * @param v
	 * @returns {string}
	 */
	formatDate(format, v) {
		if (format === '') {
			format = 'Y-m-d H:i:s';
		}
		let dateObj;
		if (v instanceof Date) {
			dateObj = v;
		} else {
			if (typeof v === 'undefined') {
				v = new Date().getTime();
			} else if (/^(-)?\d{1,10}$/.test(v)) {
				v = v * 1000;
			} else if (/^(-)?\d{1,13}$/.test(v)) {
				v = v * 1000;
			} else if (/^(-)?\d{1,14}$/.test(v)) {
				v = v * 100;
			} else if (/^(-)?\d{1,15}$/.test(v)) {
				v = v * 10;
			} else if (/^(-)?\d{1,16}$/.test(v)) {
				v = v * 1;
			} else {
				return v;
			}
			dateObj = new Date(v);
		}
		//
		format = format.replace(/Y/g, dateObj.getFullYear());
		format = format.replace(/m/g, this.zeroFill(dateObj.getMonth() + 1, 2));
		format = format.replace(/d/g, this.zeroFill(dateObj.getDate(), 2));
		format = format.replace(/H/g, this.zeroFill(dateObj.getHours(), 2));
		format = format.replace(/i/g, this.zeroFill(dateObj.getMinutes(), 2));
		format = format.replace(/s/g, this.zeroFill(dateObj.getSeconds(), 2));
		return format;
	},

	/**
	 * 秒数转具体时间小时，分钟
	 */
	secondsFormat(result) {
		let h = Math.floor((result / 3600) % 24);
		let m = Math.floor((result / 60) % 60);
		let s = Math.floor(result % 60);
		result = '';
		if (s) {
			// if(s<10) s = '0'+s;
			result = s + "秒"
		}
		if (m > 0) {
			// if(m<10) m  = '0'+m;
			result = m + "分钟" + result;
		}
		if (h > 0) {
			// if(h<10) h = '0'+h;
			result = h + "小时" + result;
		}

		return result;
	},

	/**
	 * 获取当前时间戳
	 */
	getTimestamp(type = 's') {
		let timeStamp = new Date().getTime();
		if (type === 's') {
			return Math.ceil(timeStamp / 1000)
		} else if (type === 'ms') {
			return timeStamp
		}
	},

	/**
	 * 获取当前是周几
	 */
	getWeekDate(lang = 'cn') {
		let now = new Date();
		let day = now.getDay();
		let weeks, week;
		switch (lang) {
			case "cn":
				weeks = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
				break;
			case 'en':
				weeks = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
				break;
		}
		week = weeks[day];
		return week;
	},

	/**
	 * Map转对象
	 */
	Map2Obj(strMap) {
		let obj = Object.create(null);
		for (let [k, v] of strMap) {
			obj[k] = v;
		}
		return obj;
	},

	/**
	 * Map转JSON
	 * @return {string}
	 */
	Map2JSON(map = new Map()) {
		return JSON.stringify(this.Map2Obj(map));
	},

	/**
	 * d
	 */
	Obj2Map(obj = {}) {
		let strMap = new Map();
		for (let k of Object.keys(obj)) {
			strMap.set(k, obj[k]);
		}
		return strMap;
	},
	/**
	 *json转换为map
	 */
	JSON2Map(jsonStr) {
		return this.Obj2Map(JSON.parse(jsonStr));
	},

	/**
     flex 切割显示
	 */
	splitPage(lists, rows, cols) {
		console.log(lists);
		let data = this.clone(lists);
		let t = typeof data;
		let num = rows * cols;
		if (t !== 'undefined') {
			let temp = [], i, len;
			for (i = 0, len = data.length; i < len; i += num) {
				temp.push(data.slice(i, i + num));
			}
			// console.log(temp);
			return temp;
		}
	},
	splitNum(lists, num) {
		let data = this.clone(lists);
		let t = typeof data;
		if (t !== 'undefined') {
			let temp = [], i, len;
			for (i = 0, len = data.length; i < len; i += num) {
				temp.push(data.slice(i, i + num));
			}
			// console.log(temp);
			return temp;
		}
	},

	/**
	 * 克隆对象
	 * @param myObj
	 * @returns {*}
	 */
	clone(myObj) {
		if (typeof (myObj) !== 'object') return myObj;
		if (myObj === null) return myObj;
		//
		if (this.likeArray(myObj)) {
			let [...myNewObj] = myObj;
			return myNewObj;
		} else {
			let {...myNewObj} = myObj;
			return myNewObj;
		}
	},
	isNullOrUndefined(obj) {
		return typeof obj === "undefined" || obj === null;
	},

	likeArray(obj) {
		return this.isNullOrUndefined(obj) ? false : typeof obj.length === 'number';
	},

	/**
	 * 移动端获取显示界面比例
	 */
	getScale() {
		let winW = document.documentElement.clientWidth || document.body.clientWidth;
		let winH = document.documentElement.clientHeight || document.body.clientHeight;
		return winW / 375;
	},

	/**
	 * 校验字符串是否为数字
	 */
	isNumber(str) {
		let n = Number(str);
		// if (!isNaN(n))
		// {
		//     alert("是数字");
		// }

		return !isNaN(n) === true;
	},

	/**
	 * 字符串转时间戳
	 * @param str
	 */
	str2timestamp(str) {
		str = str.replace(/-/g, '/');
		let t = Date.parse(new Date(str));
		t = t / 1000;
		return t
	},

	//根据字段排序前置方法
	compare(property) {
		return (a, b) => {
			let value1 = a[property];
			let value2 = b[property];
			return value1 - value2;
		}
	},

	//获取最大的索引值
	getMaxZIndex() {
		let arr = [...document.body.querySelectorAll('*')].map(e => +window.getComputedStyle(e).zIndex || 0);
		return arr.length ? Math.max(...arr) + 1 : 0
	},

	//列表转树形
	listToTree(srcList, keyName = 'parentid') {
		let result = []
		// reduce收集所有节点信息存放在对象中，可以用forEach改写，不过代码会多几行
		let nodeInfo = srcList.reduce((data, node) => (data[node.id] = node, data), {})

		// forEach给所有元素找妈妈
		srcList.forEach(node => {
			if (!node[keyName]) {
				result.push(node)
				return
			}

			let parent;
			try {
				parent = nodeInfo[node[keyName]]
				parent.children = parent.children || [];
				if (!parent.children) {
					parent.children = [];
				}
			} catch (e) {
				parent.children = [];
			}
			parent.children.push(node)
		})
		return result
	},
	// 防抖
	_debounceTimer: null,
	_debounce(fn, delay) {
		delay = delay || 200;
		return function () {
			let th = this;
			let args = arguments;
			if (this._debounceTimer) {
				clearTimeout(this._debounceTimer);
			}
			this._debounceTimer = setTimeout(function () {
				this._debounceTimer = null;
				fn.apply(th, args);
			}, delay);
		};
	},
	debounce(fn, delay) {
		// 维护一个 timer
		let timer = null;

		return function () {
			// 获取函数的作用域和变量
			let context = this;
			let args = arguments;

			clearTimeout(timer);
			timer = setTimeout(function () {
				fn.apply(context, args);
			}, delay)
		}
	},
	// 节流

	_throttle(fn, interval, ...args) {
		let _throttleTimer = null;
		let last;
		interval = interval || 200;
		return function () {
			let th = this;
			// let args = arguments;
			let now = +new Date();
			if (last && now - last < interval) {
				clearTimeout(_throttleTimer);
				_throttleTimer = setTimeout(function () {
					last = now;
					fn.apply(th, args);
				}, interval);
			} else {
				last = now;
				fn.apply(th, args);
			}
		}
	},
	throttle(fn, t) {
		let last, timer, interval = t || 500;
		return function () {
			let args = arguments,
				now = +new Date();
			if (last && now - last < interval) {
				clearTimeout(timer);
				timer = setTimeout(() => {
					last = now;
					fn.apply(this, args);
				}, interval);
			} else {
				last = now;
				fn.apply(this, args);
			}
		}
	},
	setCookie(c_name, value, isSetExpire = false, seconds = 60) {
		let exdate = new Date();
		let cookieStr = c_name + "=" + escape(value) + ";path=/"
		if (isSetExpire) {
			exdate.setTime(exdate.getTime() + seconds);
			console.log(exdate.getTime());
			console.log(exdate.toGMTString());
			cookieStr += ";expires=" + exdate.toUTCString();
		}

		document.cookie = cookieStr;
		console.log('设置cookie结束');
	},
	/**
	 * 计算两个数的百分比
	 * @param childNum
	 * @param parentNum
	 * @param decimal
	 * @returns {number}
	 */
	calcPercent(childNum, parentNum, decimal) {
		decimal = decimal || 2;
		return Number((childNum / parentNum).toFixed(decimal));
	},

	/**
	 * 获取给定最大值和最小值之间的随机数
	 * @param max 最大值
	 * @param min 最小值
	 */
	random(min, max) {
		return Math.random() * (max - min) + min;
	},


	/**
	 * 自定义计时器，setTimeout代替setInterval实现
	 */
	intervalTimer: null,
	myInterval(func, wait) {
		let interv = function () {
			func.call(null);
			this.intervalTimer = setTimeout(interv, wait);
		}
		this.intervalTimer = setTimeout(interv, wait);

	},
	clearMyInterval() {
		if (this.intervalTimer) {
			window.clearTimeout(this.intervalTimer);
			this.intervalTimer = null;
		}
	},
	/**
	 * 自定义计时器，setTimeout代替setInterval实现
	 */


	//校验是否是空对象
	isEmptyObj(obj) {
		return JSON.stringify(obj) === '{}';
	},

	/**
	 * 按给定单位转换秒
	 */
	transformSecondByUnit(seconds, unit) {
		let unitText = '分钟', targetNumber = 0;
		switch (unit) {
			case 'hour':
				targetNumber = seconds / 60 / 60;
				unitText = '小时';
				break;
			case 'day':
				targetNumber = seconds / 60 / 60 / 24;
				unitText = '天';
				break;
			case 'week':
				targetNumber = seconds / 60 / 60 / 24 / 7;
				unitText = '周';
				break;
			default:
				//默认按分钟转换

				targetNumber = seconds / 60;
				break
		}
		return targetNumber.toFixed(2) + ' ' + unitText;
	},

	//获取数组的值
	getArrayValue(array, key) {
		key = key || "value";
		var res = [];
		if (array) {
			array.forEach(function (t) {
				res.push(t[key]);
			});
		}
		return res;
	},
	//数组转对象
	array2obj(array, key) {
		var resObj = {};
		for (var i = 0; i < array.length; i++) {
			resObj[array[i][key]] = array[i];
		}
		return resObj;
	},
	/**
	 * 秒数转 00：00：00格式
	 */
	secondToHms(seconds){
		let h,m,s;
		h = this.zeroFill(Math.floor(seconds/3600),2);
		m = this.zeroFill(Math.floor(seconds/60%60),2)
		s = this.zeroFill(Math.floor(seconds%60),2);
		return `${h}:${m}:${s}`;
	},

	/**
	 *  Map相关操作，适用于保存和校验唯一值，如限定时间内只能存取一次验证码，
	 */
	/**
	 * 保存Map 传入一个Map的名称，如果在本地内存中查询到对应的Map，则将指定的key值和键值保存为map并更新本地内存，如果不存在，则创建Map并更新内存
	 *
	 * @param MapName
	 * @param key
	 * @param value
	 */
	saveMap(MapName, key = '', value) {
		//转换整数位字符串，兼容json只能是字符串的问题
		if(typeof key === 'number') key += '';
		let timestamp = this.getTimestamp()
		//如果不传键值，则取当前时间戳作为键值
		value = value ? value : timestamp;
		key = key?key:`__temp_${timestamp}__`;

		let temp = localStorage.getItem(MapName);
		//获取不到对应内存数据，则创建
		let _Map = (!temp) ? new Map() : this.JSON2Map(temp);
		// console.log('_Map', _Map);
		_Map.set(key, value);
		localStorage.setItem(MapName, this.Map2JSON(_Map));
	},
	//获取Map
	getMap(MapName, key) {
		let oJSON = localStorage.getItem(MapName);
		if(!oJSON){
			return oJSON;
		}

		let _Map = this.JSON2Map(oJSON);
		if(key) {
			if(typeof key === 'number') key +='';
			return _Map.get(key);
		}
		return _Map;

	},
	//移除指定Map的指定键
	removeMap(MapName, key) {
		if(typeof key === 'number') key +='';
		// debugger
		let _Map = this.getMap(MapName);

		for (let [index, value] of _Map.entries()) {
			if (key === index) {
				_Map.delete(index);
			}
		}
		localStorage.setItem(MapName, this.Map2JSON(_Map));
	},
	//清空指定Map
	clearMap(MapName) {
		localStorage.setItem(MapName, '{}');
	},

	//获取URL参数
	getQueryString(name) {
		let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
		let r = window.location.search.substr(1).match(reg);
		if (r != null) {
			return decodeURIComponent(r[2]);
		}
		return null;
	},

	//uri编码
	uriEncode(url){
		return encodeURIComponent(url);
	},
	/**获取微信code
	 * @param appid 微信公众号appid
	 * @param uri   获取之后跳转的地址，可以带参数，不需要url编码
	 * @param state 其他参数
	 * @param scope 可选snsapi_base 静默获取，用户无感知，只能获取到基本信息；snsapi_userinfo 授权获取，需要用户授权，能获取到具体信息
	 */
	getWxAuthCodeUri(appid,uri,state=1,scope='snsapi_base'){
		let redirect_uri = encodeURIComponent(uri);
		return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
	},


    /**
     * 获取路由地址参数
     */
    getHrefQueryByName(name){
        let href = location.href.split("?");
        let query = href[1];
        console.log('href:',href,query)
        if (!query){
            return null
        }
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = query.match(reg);
        if (r != null) return decodeURI(r[2]);
        else return null;
    }


};

export default comm;
