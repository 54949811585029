import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production'

import common from "./modules/common";
import training from "./modules/training";
import user from "./modules/user";

export default new Vuex.Store({
	modules: {
		common,
		training,
		user
	},
	strict: debug,
	// plugins: debug ? [createLogger()] : []
})